
  const userEditorScriptEntry = { editorReady() {} }
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  import { createOwnerBILogger as biLogger } from '/home/builduser/work/e46fc5f0e91b8aa0/packages/members-area-notifications/target/generated/bi/createBILogger.ts';

  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, exportsWrapper, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://460ff4620fa44cba8df530afde949785@sentry.wixpress.com/5803',
    id: 'undefined',
    projectName: 'members-area-notifications',
    teamName: 'ping',
    errorMonitor: true,
  };
  const experimentsConfig = {"scopes":["test"],"centralized":true};
  const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/e46fc5f0e91b8aa0/packages/members-area-notifications/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};
  const defaultTranslations = {"app.widget.title":"Notifications","app.widget.empty-state.title":"No Notifications Yet","app.widget.empty-state.subtitle":"Make comments, like posts or follow members to get things going.","app.widget.mark-all-as-read":"Mark all as read ({unseenCount})","app.widget.notification-item.time-ago":"{time, date}","app.widget.notification-item.time-ago.relative":"{time, timeRelative}","app.settings.tabs.design":"Design","app.settings.tabs.design.text-color.title":"Text color","app.settings.tabs.design.title-font.title":"Title text","app.settings.tabs.design.primary-font.title":"Notification description text","app.settings.tabs.design.indicator-color.title":"Notification circle color and opacity","app.settings.tabs.design.background-section.title":"Backgrounds and borders","app.settings.tabs.design.background-color.title":"Background opacity and color","app.settings.tabs.design.border-color.title":"Border opacity and color","app.settings.tabs.design.border-width.title":"Border width (px)"};
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    biLogger,
    shouldUseEssentials: true,
    artifactId: 'members-area-notifications',
    appDefId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  const useAPIsFromExports = typeof editorScriptEntry.exports === "function";
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI,
      shouldSkipAPIOverrides: useAPIsFromExports,
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
